import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Cont"},[_vm._m(0),_c('div',{staticClass:"InputsCont d-flex flex-wrap align-start"},[_vm._l((_vm.paymentsWarranty),function(payment,index){return _c('div',{key:index,staticClass:"Line d-flex justify-space-between align-center"},[_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"label":'Installment ' + payment.id,"type":"number"},on:{"input":function($event){return _vm.updateInstallmentName(payment.id, index)}},model:{value:(payment.name),callback:function ($$v) {_vm.$set(payment, "name", $$v)},expression:"payment.name"}})],1),_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"label":"Percentage","type":"number"},model:{value:(payment.percentage),callback:function ($$v) {_vm.$set(payment, "percentage", $$v)},expression:"payment.percentage"}})],1),_c('div',{staticClass:"Row"},[_c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"auto","content-class":"elevation-3","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"PPW Due Date"},model:{value:(payment.paymentDate),callback:function ($$v) {_vm.$set(payment, "paymentDate", $$v)},expression:"payment.paymentDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(payment.showCalendar),callback:function ($$v) {_vm.$set(payment, "showCalendar", $$v)},expression:"payment.showCalendar"}},[_c(VDatePicker,{attrs:{"no-title":"","color":"#003D6D"},on:{"input":function($event){payment.showCalendar = false}},model:{value:(payment.paymentDate),callback:function ($$v) {_vm.$set(payment, "paymentDate", $$v)},expression:"payment.paymentDate"}})],1)],1),_c('div',{staticClass:"Row"},[_c(VSelect,{attrs:{"label":"Clause"}})],1),_c('div',{staticClass:"remove-button"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.removePaymentWarranty(payment.id)}}},[_c(VIcon,[_vm._v(" mdi-delete ")])],1)],1)])}),_c('div',{staticClass:"finishButtonCont d-flex justify-start align-center"},[_c(VBtn,{staticClass:"finishBtn",attrs:{"disabled":_vm.addPaymentDisabled,"rounded":"","large":"","text":""},on:{"click":function($event){return _vm.addPaymentWarranty()}}},[_vm._v(" Add New ")])],1)],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TitleCont d-flex justify-start align-center flex-wrap"},[_c('h5',[_vm._v("Premium Payment Warranty")])])}]

export { render, staticRenderFns }